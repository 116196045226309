export const CacheManager = {
  getItem(key: string) {
    if (typeof window != "undefined") {
      const data = window.localStorage.getItem(key);
      if (typeof data === "string") {
        return JSON.parse(data);
      }
      return data;
    }
  },
  setItem(key: string, value: string) {
    if (typeof window != "undefined") {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  },
  removeItem(key: string) {
    if (typeof window != "undefined") {
      window.localStorage.removeItem(key);
    }
  },
  removeAll() {
    if (typeof window != "undefined") {
      window.localStorage.clear();
    }
  },
};

export const setOverflowStyleInBody = (isEnabled: boolean) => {
  if (isEnabled) {
    document.body.style.overflow = "auto";
  } else {
    document.body.style.overflow = "hidden";
  }
};

export const downloadFile = async (fileName: string, uri: string) => {
  fetch(uri, { mode: "no-cors" }).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = fileName;
      alink.click();
    });
  });
};

export const paragraphParser = (htmlNode: string) => {
  return htmlNode.replace(/<\/?p[^>]*>/g, "");
};
