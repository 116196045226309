import React from "react";
import headerLogo from "../../assets/images/client-logo-header.png";
import { authContext } from "../../config/AdalConfig";
import "../../layouts/header/header.scss";
import { useAuth } from "../../provider/AuthProvider";
import { LOCAL_STORAGE_KEYS } from "../../utils/enums";
import { CacheManager } from "../../utils/helper";

interface IHeaderProps {}

const Header = React.memo(({}: IHeaderProps): JSX.Element => {
  const auth = useAuth();
  const userInfo = auth?.dashboardInfo?.data;
  return (
    <>
      <div className="header-wrapper">
        <div className="main-logo">
          <a href="/">
            <img
              src={headerLogo}
              alt="Logo"
            />
          </a>
          <span className="fund-name">
            {userInfo?.addressDetails?.firstName} {userInfo?.addressDetails?.lastName}
          </span>
        </div>
        <div className="logout-wrapper">
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base signout-btn font-900"
            onClick={() => {
              authContext.logOut();
              CacheManager.removeItem(LOCAL_STORAGE_KEYS.LOGIN_SUCCESS_ALERT);
            }}
          >
            SIGN OUT
          </button>
        </div>
      </div>
    </>
  );
});
export default Header;
