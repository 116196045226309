import React from "react";
import "./App.scss";
import ContentRenderer from "./layouts/content-renderer";
import { useAuth } from "./provider/AuthProvider";
import { UserConsentProvider } from "./provider/UserConsentProvider";
import { ProtectedRoutes, PublicRoutes } from "./router/AppNavigation";

const App = React.memo(() => {
  const auth = useAuth();
  return (
    <>
      {auth.isAuthenticated === true ? (
        <UserConsentProvider>
          <ContentRenderer>
            <ProtectedRoutes isAuthenticated={auth.isAuthenticated} />
          </ContentRenderer>
        </UserConsentProvider>
      ) : auth.isAuthenticated === false ? (
        <PublicRoutes isAuthenticated={auth.isAuthenticated} />
      ) : (
        <></>
      )}
    </>
  );
});

export default App;
