import axios, { AxiosError } from "axios";
import React from "react";
import { adalConfig, authContext } from "../config/AdalConfig";
import { IAxiosContactUsUpdateRequest } from "../utils/interfaces";

interface IApiContextProps {
  getUserInfoApi: (cb: Function, errorCallback?: Function) => void;
  getDashboardInfoApi: (cb: Function) => void;
  getMarketDashboardInfoApi: (cb: Function) => void;
  getClientReportApi: (year: number, cb: Function) => void;
  getCaseReportApi: (year: number, cb: Function) => void;
  getUsAndNonUsSecurityClassActionReportApi: (typeId: number, cb: Function) => void;
  updateContactInfo: (payload: IAxiosContactUsUpdateRequest, cb: Function, error?: Function) => void;
  getCountyState: (type: number, cb: Function) => void;
  getDownloadStream: (fileName: string, cb: Function) => void;
}
interface IApiProviderProps {
  children: React.ReactNode;
}
const ERROR = "API Provider not initialized";
const ApiContext = React.createContext<IApiContextProps>({
  getUserInfoApi: () => {
    throw ERROR;
  },
  getDashboardInfoApi: () => {
    throw ERROR;
  },
  getMarketDashboardInfoApi: () => {
    throw ERROR;
  },
  getClientReportApi: () => {
    throw ERROR;
  },
  getCaseReportApi: () => {
    throw ERROR;
  },
  getUsAndNonUsSecurityClassActionReportApi: () => {
    throw ERROR;
  },
  updateContactInfo: () => {
    throw ERROR;
  },
  getCountyState: () => {
    throw ERROR;
  },
  getDownloadStream: () => {
    throw ERROR;
  },
});

export const ApiProvider = React.memo<IApiProviderProps>(({ children }: IApiProviderProps): JSX.Element => {
  const apiAxios = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/api/v1` });
  const getAxiosConfig = React.useCallback(() => {
    const cachedToken = authContext.getCachedToken(adalConfig.clientId);
    const axiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    } as any;
    if (cachedToken) {
      axiosRequestConfig.headers.Authorization = `Bearer ${cachedToken}`;
    } else {
      authContext.acquireToken(authContext.config.clientId, (error, token) => {
        axiosRequestConfig.headers.Authorization = `Bearer ${token}`;
      });
    }
    return axiosRequestConfig;
  }, []);

  const get = React.useCallback(
    (url: string, callback?: Function) => {
      apiAxios
        .get(`${url}`, getAxiosConfig())
        .then((axiosResponse) => {
          if (callback) {
            callback(axiosResponse);
          }
        })
        .catch((error: AxiosError) => {
          console.error(error);
        });
    },
    [apiAxios, getAxiosConfig]
  );
  const post = React.useCallback(
    (url: string, payload: any, callback?: Function, errorCallback?: Function) => {
      apiAxios
        .post(`${url}`, payload, getAxiosConfig())
        .then((axiosResponse) => {
          if (callback) {
            callback(axiosResponse);
          }
        })
        .catch((error: AxiosError) => {
          if (errorCallback) {
            errorCallback(error);
          }
          console.error(error);
        });
    },
    [apiAxios, getAxiosConfig]
  );
  const apis = React.useMemo<IApiContextProps>(() => {
    return {
      getUserInfoApi: (cb: Function, errorCallback?: Function) => {
        post(`/Users/CheckLoginUser`, {}, cb, errorCallback);
      },
      getDashboardInfoApi: (cb: Function) => {
        get(`/Dashboard/details`, cb);
      },
      getMarketDashboardInfoApi: (cb: Function) => {
        get(`/Dashboard/market/details`, cb);
      },
      getClientReportApi: (year: number, cb: Function) => {
        get(`/Dashboard/client/${year}/report`, cb);
      },
      getCaseReportApi: (year: number, cb: Function) => {
        get(`/Dashboard/case/${year}/report`, cb);
      },
      getUsAndNonUsSecurityClassActionReportApi: (typeId: number, cb: Function) => {
        get(`/Dashboard/section/${typeId}/report`, cb);
      },
      updateContactInfo: (payload: IAxiosContactUsUpdateRequest, cb: Function, error?: Function) => {
        post(`/Dashboard/update/contact`, payload, cb, error);
      },
      getCountyState: (type: number, cb: Function) => {
        get(`/Dashboard/masters/get?type=${type}`, cb);
      },
      getDownloadStream: async (fileName: string, cb: Function) => {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/Dashboard/download?filePath=${fileName}`, {
          method: "POST",
          responseType: "blob",
          ...getAxiosConfig(),
        })
          .then((res) => res.arrayBuffer())
          .then((r) => cb(r));
      },
    };
  }, [get, getAxiosConfig, post]);
  return <ApiContext.Provider value={apis}>{children}</ApiContext.Provider>;
});

export const useApi = () => React.useContext(ApiContext);
