import { ListView, ListViewItemProps } from "@progress/kendo-react-listview";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../layouts/left-menu/leftmenu.scss";
import { LEFT_MENU } from "../../utils/constant";
import { ILeftMenuProps } from "../../utils/interfaces";

const LeftMenu = React.memo((): JSX.Element => {
  const location = useLocation();

  return (
    <>
      <div className="left-sidebar-panel">
        <ListView
          className="left-menu-wrapper k-listview-item row p-2 border-bottom align-middle"
          data={LEFT_MENU}
          item={(props: ListViewItemProps) => {
            const data = props.dataItem as ILeftMenuProps;
            return (
              <Link
                to={
                  data?.path === ""
                    ? "mailto:marketingteam@labaton.com"
                    : data?.path
                }
                className={location.pathname === data?.path ? "list-items active" : "list-items"}
              >
                <span className={data.class}></span>
                <span className="icon-name">{data.title}</span>
              </Link>
            );
          }}
        />
      </div>
    </>
  );
});
export default LeftMenu;
