import React from "react";
import "../../layouts/footer/footer.scss";
interface IContentRendererProps {}

const Footer = React.memo(({}: IContentRendererProps): JSX.Element => {
  return (
    <>
      <footer className="footer-panel">
        <p>
          {" "}
          ANY UNAUTHORIZED ACCESS IS POTENTIALLY A VIOLATION OF LAW. COPYRIGHT
          2023.
        </p>
      </footer>
    </>
  );
});
export default Footer;
