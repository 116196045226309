import { ILeftMenuProps } from "./interfaces";
export const ROUTES = {
  HOME: "/home",
  REPORTS: "/reports",
  US_SECURITIES_CLASS_ACTION: "/us-securities-class-actions",
  NON_US_SECURITIES_CLASS_ACTION: "/non-us-securities-class-actions",
  SETTLEMENTS: "/settlements",
  CONTACT_US: "/contact-us",
  LOGIN: "/login",
};
export const LEFT_MENU: Array<ILeftMenuProps> = [
  {
    title: "Home",
    path: ROUTES.HOME,
    class: "menu-icon home-icon",
  },
  {
    title: "Reports",
    path: ROUTES.REPORTS,
    class: "menu-icon report-icon",
  },
  {
    title: "U.S. SECURITIES CLASS ACTIONS",
    path: ROUTES.US_SECURITIES_CLASS_ACTION,
    class: "menu-icon us-securities-icon",
  },
  {
    title: "NON-U.S. SECURITIES CLASS ACTIONS",
    path: ROUTES.NON_US_SECURITIES_CLASS_ACTION,
    class: "menu-icon non-us-securities-icon",
  },
  {
    title: "SETTLEMENTS",
    path: ROUTES.SETTLEMENTS,
    class: "menu-icon settlements-icon",
  },
  {
    title: "CONTACT US",
    path: "", //ROUTES.CONTACT_US,
    class: "menu-icon contact-us-icon",
  },
];
