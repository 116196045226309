import React from "react";
import { runWithAdal } from "react-adal";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { authContext } from "./config/AdalConfig";
import "./index.scss";
import { ApiProvider } from "./provider/ApiProvider";
import { AuthProvider } from "./provider/AuthProvider";
import reportWebVitals from "./reportWebVitals";
// The flag is required, if auto redirect to login page [Set - False]
const DO_NOT_LOGIN = true;
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
runWithAdal(
  authContext,
  () => {
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <ApiProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ApiProvider>
        </BrowserRouter>
      </React.StrictMode>
    );
  },
  DO_NOT_LOGIN
);
authContext.handleWindowCallback();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
