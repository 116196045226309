export enum LOCAL_STORAGE_KEYS {
  LOGIN_SUCCESS_ALERT = "LOGIN_SUCCESS_ALERT",
  ACCESS_TOKEN = "ACCESS_TOKEN",
}

export enum API_STATUS_CODE {
  STATUS_200 = 200,
  STATUS_400 = 400,
  STATUS_500 = 500,
}
