import React from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../left-menu";

interface IContentRendererProps {
  children: React.ReactNode;
}

const ContentRenderer = React.memo(
  ({ children }: IContentRendererProps): JSX.Element => {
    return (
      <>
        <Header />
        <LeftMenu />
        {children}
        <Footer />
      </>
    );
  }
);
export default ContentRenderer;
