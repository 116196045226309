import { Loader } from "@progress/kendo-react-indicators";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "../utils/constant";
const LoginPage = React.lazy(() => import("../pages/login"));
const HomePage = React.lazy(() => import("../pages/home"));
const ReportsPage = React.lazy(() => import("../pages/reports"));
const ContactPage = React.lazy(() => import("../pages/contact"));
const USSecuritiesPage = React.lazy(() => import("../pages/us-securities"));
const NonUSSecuritiesPage = React.lazy(() => import("../pages/non-us-securities"));
const SettlementsPage = React.lazy(() => import("../pages/settlements"));
interface IProtectedRoutesProps {
  isAuthenticated: boolean | null;
}
interface IPublicRoutesProps {
  isAuthenticated: boolean | null;
}
export const ProtectedRoutes = React.memo(({ isAuthenticated }: IProtectedRoutesProps): JSX.Element => {
  return (
    <>
      <Suspense
        fallback={
          <Loader
            className="custom-loader"
            size="large"
            type="converging-spinner"
          />
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={ROUTES.HOME}
                replace
              />
            }
          />
          <Route
            path={ROUTES.HOME}
            element={<HomePage />}
          />
          <Route
            path={ROUTES.CONTACT_US}
            element={<ContactPage />}
          />
          <Route
            path={ROUTES.REPORTS}
            element={<ReportsPage />}
          />
          <Route
            path={ROUTES.US_SECURITIES_CLASS_ACTION}
            element={<USSecuritiesPage />}
          />
          <Route
            path={ROUTES.NON_US_SECURITIES_CLASS_ACTION}
            element={<NonUSSecuritiesPage />}
          />
          <Route
            path={ROUTES.SETTLEMENTS}
            element={<SettlementsPage />}
          />
          <Route
            path="/*"
            element={
              isAuthenticated === true ? (
                <Navigate
                  to={ROUTES.HOME}
                  state={window.location.pathname + window.location.search}
                  replace
                />
              ) : (
                <></>
              )
            }
          />
        </Routes>
      </Suspense>
    </>
  );
});

export const PublicRoutes = React.memo(({ isAuthenticated }: IPublicRoutesProps): JSX.Element => {
  return (
    <>
      <Suspense
        fallback={
          <Loader
            className="custom-loader"
            size="large"
            type="converging-spinner"
          />
        }
      >
        <Routes>
          <Route
            path="/"
            element={<LoginPage />}
          />
          <Route
            path={ROUTES.LOGIN}
            element={<LoginPage />}
          />
          <Route
            path="/*"
            element={
              isAuthenticated === false ? (
                <Navigate
                  to={ROUTES.LOGIN}
                  state={window.location.pathname + window.location.search}
                  replace
                />
              ) : (
                <></>
              )
            }
          />
        </Routes>
      </Suspense>
    </>
  );
});
