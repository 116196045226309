import { AxiosResponse } from "axios";
import React from "react";
import { authContext } from "../config/AdalConfig";
import { API_STATUS_CODE } from "../utils/enums";
import {
  IAxiosCountryStateResponse,
  IAxiosDashboardAddressContactInfoResponse,
  IAxiosUserInfoResponse,
  ICountryStateData,
  IUserInfoData,
} from "../utils/interfaces";
import { useApi } from "./ApiProvider";

interface IAuthProviderProps {
  children: React.ReactNode;
}

interface IAuthContextType {
  isAuthenticated: boolean | null;
  isValidUser?: boolean;
  invalidUserMessage: string;
  accessToken: string | null;
  user: IUserInfoData | null;
  login: () => void;
  logout: () => void;
  dashboardInfo: IAxiosDashboardAddressContactInfoResponse | null;
  setDashboardInfo: Function;
  countryList: Array<ICountryStateData>;
  stateList: Array<ICountryStateData>;
}
const COUNTRY_ID = 9;
const STATE_ID = 10;
const AuthContext = React.createContext<IAuthContextType | undefined>(undefined);
const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const api = useApi();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);
  const [user, setUser] = React.useState<IUserInfoData | null>(null);
  const [accessToken, setAccessToken] = React.useState<string | null>(null);
  const [dashboardInfo, setDashboardInfo] = React.useState<IAxiosDashboardAddressContactInfoResponse | null>(null);
  const [countryList, setCountryList] = React.useState<Array<ICountryStateData>>([]);
  const [stateList, setStateList] = React.useState<Array<ICountryStateData>>([]);
  const [isValidUser, setIsValidUser] = React.useState<boolean>();
  const [invalidUserMessage, setInvalidUserMessage] = React.useState<string>("");
  React.useEffect(() => {
    const bootstrapApi = () => {
      api.getDashboardInfoApi((response: AxiosResponse) => {
        const result = response.data as IAxiosDashboardAddressContactInfoResponse;
        if (result.response.code === API_STATUS_CODE.STATUS_200) {
          setDashboardInfo(result);
        }
      });
      api.getCountyState(COUNTRY_ID, (response: AxiosResponse) => {
        const result = response.data as IAxiosCountryStateResponse;
        if (result.response.code === API_STATUS_CODE.STATUS_200) {
          setCountryList(result.data);
        }
      });
      api.getCountyState(STATE_ID, (response: AxiosResponse) => {
        const result = response.data as IAxiosCountryStateResponse;
        if (result.response.code === API_STATUS_CODE.STATUS_200) {
          setStateList(result.data);
        }
      });
    };
    bootstrapApi();
  }, [api]);
  React.useEffect(() => {
    const cachedToken = authContext.getCachedToken(authContext.config.clientId);
    if (!cachedToken) {
      authContext.acquireToken(authContext.config.clientId, (error, token) => {
        if (error) {
          setIsAuthenticated(false);
          setAccessToken(null);
        } else {
          setIsAuthenticated(true);
          setAccessToken(token);
        }
      });
    } else {
      setIsAuthenticated(cachedToken ? true : false);
      setAccessToken(cachedToken);
    }
  }, []);
  const bootstrapCheckUserApi = React.useCallback(() => {
    if (accessToken) {
      api.getUserInfoApi((response: AxiosResponse) => {
        const result = response.data as IAxiosUserInfoResponse;
        if (result.status) {
          if (result.response.code === API_STATUS_CODE.STATUS_200) {
            setUser(result.data);
            setIsValidUser(true);
            setInvalidUserMessage(result.response.developerMessage);
          }
        } else {
          setIsValidUser(false);
          setInvalidUserMessage(result.response.developerMessage);
        }
      });
    }
  }, [accessToken, api]);
  React.useEffect(() => {
    bootstrapCheckUserApi();
  }, [bootstrapCheckUserApi]);
  const login = () => {
    authContext.login();
  };

  const logout = () => {
    authContext.logOut();
  };

  const authValue: IAuthContextType = {
    isAuthenticated,
    user,
    login,
    logout,
    accessToken,
    dashboardInfo,
    countryList,
    stateList,
    setDashboardInfo,
    isValidUser,
    invalidUserMessage,
  };

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
